.main {
    background-image: url('../../assets/images/blake-connally-B3l0g6HLxr8-unsplash-lg.jpg');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-title {
    width: 50%;
}

.main-title:hover {
    cursor: pointer;
    color: rgb(179, 7, 7);
    -webkit-transition: color 1.5s ease-out;
    -moz-transition: color 1.5s ease-out;
    -o-transition: color 1.5s ease-out;
    transition: color 1.5s ease-out;
}
