.nav-container {
    background-color: black;
    padding: 1rem;
}

.nav-link {
    font-family: 'Montserrat', serif;
    font-size: 1.3rem;
    color: white !important;
}
