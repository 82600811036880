
body {
    color: white;
    background-color: black;
}

h1, h3, h4, h5 {
    font-family: 'Montserrat', serif;
}

p {
    font-family: 'Consolas', serif;
}

