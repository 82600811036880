.about {
    background: linear-gradient(rgb(34, 34, 33), rgb(24, 24, 23), rgb(15, 15, 15), black);
    z-index: -999;
    padding-top: 8%;
    padding-bottom: 8%;
}

.about-card {
    margin: 20%;
}

.about-text {
    line-height: 1.7;
}

@media only screen and (max-width: 1024px) {
    .about-text {
        font-size: 1.1rem;
    }
}