@import url(https://fonts.googleapis.com/css?family=Consolas|Special+Elite|Montserrat|Droid+Sans+Mono&display=swap);



body {
    color: white;
    background-color: black;
}

h1, h3, h4, h5 {
    font-family: 'Montserrat', serif;
}

p {
    font-family: 'Consolas', serif;
}


.main {
    background-image: url(/static/media/blake-connally-B3l0g6HLxr8-unsplash-lg.79cff08f.jpg);
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-title {
    width: 50%;
}

.main-title:hover {
    cursor: pointer;
    color: rgb(179, 7, 7);
    transition: color 1.5s ease-out;
}

.about {
    background: linear-gradient(rgb(34, 34, 33), rgb(24, 24, 23), rgb(15, 15, 15), black);
    z-index: -999;
    padding-top: 8%;
    padding-bottom: 8%;
}

.about-card {
    margin: 20%;
}

.about-text {
    line-height: 1.7;
}

@media only screen and (max-width: 1024px) {
    .about-text {
        font-size: 1.1rem;
    }
}
.experience {
    background: linear-gradient(black, rgb(15, 15, 15), rgb(24, 24, 23), rgb(34, 34, 33));
    z-index: -999;
    padding-top: 8%;
    padding-bottom: 8%;
}

.articles {
    background: linear-gradient(rgb(34, 34, 33), rgb(24, 24, 23), rgb(15, 15, 15), black);
    z-index: -999;
    padding-top: 8%;
    padding-bottom: 8%;
}

.articles-card {
    width: 18rem;
    background-color: white; 
    color: black;
}

.articles-img-grid {
    width: 100%;
    margin: 1%;
    padding: 5%;
    cursor: pointer;
    opacity: 0.3; 
    -moz-filter: blur(1.5px); 
    -o-filter: blur(1.5px); 
    -ms-filter: blur(1.5px); 
    filter: blur(1.5px); 
}

.articles-img-single {
    width: 70%;
    margin: 1%;
    padding: 5%;
    cursor: pointer;
    opacity: 0.4; 
    -moz-filter: blur(2px); 
    -o-filter: blur(2px); 
    -ms-filter: blur(2px); 
    filter: blur(2px); 
}

.articles-title-grid {
    position: absolute;
    cursor: pointer;
    color: white; 
    font-size: 1.3rem;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.6s;
}

.articles-title-grid:hover {
    text-shadow: 0.5px 1px 2px white;
    transition: 0.6s;
}

.articles-title-single {
    position: absolute;
    cursor: pointer;
    color: white; 
    font-weight: 800;
    text-shadow: 0px 0px 1px white;
    font-size: 1.7rem;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
}

.articles-title-grid {
    position: absolute;
    cursor: pointer;
    color: white; 
    font-size: 1.3rem;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.6s;
}

.articles-text {
    position: absolute;
    cursor: pointer;
    color: white; 
    font-size: 1.1rem;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
}

.articles-exit-icon {
    position: absolute;
    cursor: pointer;
    top: 15%;
    left: 77%;
    transform: translateX(-50%);
}

.articles-button {
    position: absolute;
    cursor: pointer;
    color: white; 
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 1200px) {
    .articles-title-grid {
        position: absolute;
        cursor: pointer;
        color: white; 
        font-size: 1rem;
        top: 20%;
        left: 50%;
        transform: translateX(-50%);
        transition: 0.6s;
    }

    .articles-title-single {
        position: absolute;
        cursor: pointer;
        color: white; 
        font-weight: 800;
        text-shadow: 0px 0px 1px white;
        font-size: 1.1rem;
        top: 20%;
        left: 50%;
        transform: translateX(-50%);
    }
    
  }

.contact {
    background: linear-gradient(black, rgb(15, 15, 15), rgb(24, 24, 23), rgb(34, 34, 33));
    z-index: -999;
    padding-top: 8%;
    padding-bottom: 8%;
}

.contact-icon {
    font-size: 3rem;
    margin: 0.3em;
    cursor: pointer;
}

.contact-icon-text {
    margin-top: 1em;
}
.nav-container {
    background-color: black;
    padding: 1rem;
}

.nav-link {
    font-family: 'Montserrat', serif;
    font-size: 1.3rem;
    color: white !important;
}

